<template>
  <div class="lay_time__table_row p-x-3 p-y-1 fz14 cursor-pointer" @click="$emit('to-details')">
    <div class="lay_time__table_col">
      <template v-if="lay.lay_ports && lay.lay_ports.length && getFirstPortsWithName">
        <span v-if="getFirstPortsWithName.loading_port">Loading</span>
        <span v-if="getFirstPortsWithName.loading_port && getFirstPortsWithName.discharging_port"> - </span>
        <span v-if="getFirstPortsWithName.discharging_port">Discharging</span>
      </template>
    </div>
    <div class="lay_time__table_col flex items-center">
      <template v-if="lay.lay_ports && lay.lay_ports.length">
        <span v-if="getFirstPortsWithName">
          <span class="name-port" :class="{ 'w100Proc': !getFirstPortsWithName.discharging_port }"
            v-if="getFirstPortsWithName.loading_port">{{ getFirstPortsWithName.loading_port.name }},
            {{ getFirstPortsWithName.loading_port.country }} </span>
          <span v-if="getFirstPortsWithName.loading_port && getFirstPortsWithName.discharging_port" class="marginSpace">
            - </span>
          <span class="name-port" :class="{ 'w100Proc': !getFirstPortsWithName.loading_port }"
            v-if="getFirstPortsWithName.discharging_port">{{ getFirstPortsWithName.discharging_port.name }},
            {{ getFirstPortsWithName.discharging_port.country }} </span>
        </span>

        <div v-if="getPortsWithoutEmpty.length > 1"
          class="inline-flex w-5 h-5 m-l-1 rounded-full border border-dark fz11 box-border">
          <span class="m-auto" v-if="getFirstPortsWithName">+{{ getPortsWithoutEmpty.length - 1 }}</span>
          <!--          <span class="m-auto" v-else>{{// getPortsWithoutEmpty.length}}</span>-->
        </div>
      </template>
    </div>
    <div class="lay_time__table_col">{{ charterer }} {{ lay.cp_date | formatDateWithotTZ }}</div>
    <div class="lay_time__table_col">{{ getDemurrage() | formatNumberTwo }}</div>
    <div class="lay_time__table_col">
      {{ getTotalEquals >= getTimeAllowed() ? 'Demurrage' : 'Dispatch' }}
      <!--      {{getTotalEquals}} {{getTimeAllowed()}}-->
    </div>
    <div class="lay_time__table_col">
      <div class="option-btn_wrap flex">
        <button class="option-btn text-white hover:text-blue" @click.stop="openMenu">
          <span v-if="lay.status == 'confirmed'"
            class="hover:bg-gray-dark inline-flex bg-green p-x-2 p-y-0.5 fz13 fw500 rounded-full">Confirmed</span>
          <span v-else-if="lay.status == 'under_dispute'"
            class="hover:bg-gray-dark inline-flex bg-purple p-x-2 p-y-0.5 fz13 fw500 rounded-full">Under dispute</span>
          <span v-else class="hover:bg-gray-dark inline-flex bg-red p-x-2 p-y-0.5 fz13 fw500 rounded-full">Not
            confirmed</span>
        </button>

        <div class="option-btn_list left-0 right-auto top-full" tabindex="0" @blur="closeMenu()" v-show="isShowMenu"
          ref="option_list">
          <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('not_confirmed')">
            Not confirmed
            <svg :class="{ 'opacity-100': lay.status == 'not_confirmed' }" class="m-l-2 m-r-0 opacity-0" width="24"
              height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z"
                fill="#319FEF" />
            </svg>
          </div>
          <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('under_dispute')">
            Under dispute
            <svg :class="{ 'opacity-100': lay.status == 'under_dispute' }" class="m-l-2 m-r-0 opacity-0" width="24"
              height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z"
                fill="#319FEF" />
            </svg>
          </div>
          <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('confirmed')">
            Confirmed
            <svg :class="{ 'opacity-100': lay.status == 'confirmed' }" class="m-l-2 m-r-0 opacity-0" width="24"
              height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z"
                fill="#319FEF" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="lay_time__table_col">
      <options class="flex" :options="['edit', 'archive']" @editItem="$emit('on-edit')"
        @archiveItem="$emit('on-archive')" />
    </div>
  </div>
</template>

<script>
import options from '@/components/reuse/options'
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    lay: Object
  },
  components: {
    options
  },
  data() {
    return {
      charterer: null,
      isShowMenu: false
    }
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract
    }),
    getPortsWithoutEmpty() {
      return this.lay.lay_ports.filter(a => a.loading_port || a.discharging_port)
    },
    getFirstPortsWithName() {
      return this.lay.lay_ports.find(a => a.loading_port || a.discharging_port)
    },
    getTotalEquals() {
      let summ = 0
      this.lay.lay_times.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
    getDemTime() {
      return Math.abs((Math.round(this.getTimeAllowed() * 10000000) / 10000000) - (Math.round(this.getTotalEquals * 10000000) / 10000000))
    },
  },
  methods: {
    ...mapActions(['axiosGetClientsByIdReturnResponse', 'axiosChangeLayStatus']),
    async getCharterer() {
      let charterer = null
      if (this.lay['additional_info']['charterer']) {
        charterer = this.activeContract.cargo_contracts.find(el => {
          return el.contract_type == 'CARGO' && el.id == this.lay['additional_info']['charterer']
        })
      } else {
        charterer = this.activeContract.cargo_contracts.find(el => {
          return el.contract_type == 'CARGO' && el.cp_date == this.lay.cp_date
        })
      }
      if (charterer) {
        if (Number.isInteger(charterer.owner)) {
          charterer.owner = await this.axiosGetClientsByIdReturnResponse(charterer.owner)
        }

        this.charterer = charterer.owner ? charterer.owner.company_name : ''
      }

    },
    getTimeAllowed() {
      let a_time = 0

      this.lay.lay_ports.forEach(el => {
        // if(el.discharging_port){
        //   a_time += el.discharging_quantity / el.discharging_rate
        // }
        // if(el.loading_port){
        //   a_time += el.loading_quantity / el.loading_rate
        // }
        if (this.lay.additional_info.reversible_between && this.lay.additional_info.reversible_between === 'Loading ports') {
          if (el.loading_port) {
            if (Array.isArray(el.loading_quantity) && Array.isArray(el.loading_rate)) {
              a_time += el.loading_quantity.reduce((acc, val, index) =>
                acc + (val ?? 0) / (el.loading_rate[index] ?? 1), 0);
            }
            // a_time += el.loading_quantity / el.loading_rate
            // a_time +=  (Array.isArray(el.loading_quantity) == true ? el.loading_quantity.reduce((acc, val) => acc + (val ?? 0), 0) : el.loading_quantity) / (Array.isArray(el.loading_rate) == true ? el.loading_rate.reduce((acc, val) => acc + (val ?? 0), 0) : el.loading_rate)
          }
        } else if (this.lay.additional_info.reversible_between && this.lay.additional_info.reversible_between === 'Discharging ports') {
          if (el.discharging_port) {
            // a_time += el.discharging_quantity / el.discharging_rate
            if (Array.isArray(el.discharging_quantity) && Array.isArray(el.discharging_rate)) {
              a_time += el.discharging_quantity.reduce((acc, val, index) =>
                acc + (val ?? 0) / (el.discharging_rate[index] ?? 1), 0);
            }
            // a_time += (Array.isArray(el.discharging_quantity) == true ? el.discharging_quantity.reduce((acc, val) => acc + (val ?? 0), 0) : el.discharging_quantity) / (Array.isArray(el.discharging_rate) == true ? el.discharging_rate.reduce((acc, val) => acc + (val ?? 0), 0) : el.discharging_rate)
          }
        } else {
          if (el.discharging_port) {
            if (Array.isArray(el.discharging_quantity) && Array.isArray(el.discharging_rate)) {
              a_time += el.discharging_quantity.reduce((acc, val, index) =>
                acc + (val ?? 0) / (el.discharging_rate[index] ?? 1), 0);
            }
            // a_time += el.discharging_quantity / el.discharging_rate
            // a_time += (Array.isArray(el.discharging_quantity) == true ? el.discharging_quantity.reduce((acc, val) => acc + (val ?? 0), 0) : el.discharging_quantity) / (Array.isArray(el.discharging_rate) == true ? el.discharging_rate.reduce((acc, val) => acc + (val ?? 0), 0) : el.discharging_rate)
          }
          if (el.loading_port) {
            if (Array.isArray(el.loading_quantity) && Array.isArray(el.loading_rate)) {
              a_time += el.loading_quantity.reduce((acc, val, index) =>
                acc + (val ?? 0) / (el.loading_rate[index] ?? 1), 0);
            }
            // a_time += el.loading_quantity / el.loading_rate
            a_time += (Array.isArray(el.loading_quantity) == true ? el.loading_quantity.reduce((acc, val) => acc + (val ?? 0), 0) : el.loading_quantity) / (Array.isArray(el.loading_rate) == true ? el.loading_rate.reduce((acc, val) => acc + (val ?? 0), 0) : el.loading_rate)
          }
        }
      });

      this.lay.time_allowed = Math.round(a_time * 10000000) / 10000000


      // let demTime = Math.abs((Math.round(a_time*10000000)/10000000) - (Math.round(this.getTotalEquals * 10000000)/10000000))
      // let dem_rate = a_time > this.getTotalEquals ? Math.round((this.lay.demurrage_rate/2)*10000000)/10000000 :  Math.round(this.lay.demurrage_rate*10000000)/10000000
      // let result = demTime * dem_rate
      // result = Math.round(result*10000000)/10000000
      // this.lay.demurrage = result

      return Math.round(a_time * 10000000) / 10000000
    },

    getDemurrage() {
      // if(this.getTotalEquals < this.getTimeAllowed()) {
      //   return Math.trunc(this.lay.demurrage) + Math.floor(((this.lay.demurrage % 1)*100))/100
      // }
      // return this.lay.demurrage
      let dem_rate = this.getTimeAllowed() > this.getTotalEquals ? this.lay.dispatch_rate : this.lay.demurrage_rate
      let result = Math.round(this.getDemTime * 10000000) / 10000000 * Math.round(dem_rate * 10000000) / 10000000
      result = Math.round(result * 10000000) / 10000000

      this.lay.demurrage = result
      if (this.getTotalEquals < this.timeAllowed) {
        result = Math.trunc(result) + Math.floor(((result % 1) * 100)) / 100
      }
      return result
    },

    openMenu() {
      this.isShowMenu = true

      setTimeout(() => { // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu(status) {
      if (status) {
        this.lay.status = status
        this.axiosChangeLayStatus({ id: this.lay.id, status: this.lay.status })
      }
      this.isShowMenu = false
    },
  },
  mounted() {
    this.getCharterer()
  }
}
</script>

<style lang="scss" scoped>
.name-port {
  max-width: calc(50% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w100Proc {
  width: max-content !important;
  max-width: calc(100% - 35px) !important;
  ;
}

.marginSpace {
  margin: 0 2px;
}
</style>