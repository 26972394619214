var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice_table border border-dark overflow-hidden rounded-lg",class:{'with_trials': _vm.getPermissionsByType('show_accounts_in_all_operations')}},[_c('div',{staticClass:"border-r border-b rounded-tl-lg"},[(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('get-trials',{attrs:{"trialKey":"Detention Invoice"}}):_vm._e()],1),(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('div',{staticClass:"border-r border-b"}):_vm._e(),_c('div',{staticClass:"border-r border-b"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detail.time_from),expression:"detail.time_from"}],ref:"timeFrom",staticClass:"p-x-2 p-y-2",attrs:{"type":"text","placeholder":"Enter time from"},domProps:{"value":(_vm.detail.time_from)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.detail, "time_from", $event.target.value)}}})]),_c('div',{staticClass:"border-r border-b"},[_c('date-picker',{attrs:{"format":"DD.MM.YYYY","value-type":"format","type":"date","placeholder":"Enter date from"},model:{value:(_vm.detail.date_from),callback:function ($$v) {_vm.$set(_vm.detail, "date_from", $$v)},expression:"detail.date_from"}})],1),_c('div',{staticClass:"border-r border-b",class:{
      'colspan-5-7': _vm.getPermissionsByType('show_accounts_in_all_operations'),
      'colspan-4-6': !_vm.getPermissionsByType('show_accounts_in_all_operations')
    }},[_c('counterparties-drop',{attrs:{"list":_vm.timeList,"activeItem":_vm.activeTime,"removeSelectItemBeforeClick":false,"placeholder":"Select time type"},on:{"select":_vm.onSelectTime}})],1),_c('div',{staticClass:"border-b"}),_c('div',{staticClass:"border-b-blue-dark border-r border-b"}),(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('div',{staticClass:"border-b-blue-dark border-r border-b"}):_vm._e(),_c('div',{staticClass:"border-b-blue-dark border-r border-b"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detail.time_to),expression:"detail.time_to"}],ref:"timeTo",staticClass:"p-x-2 p-y-2",attrs:{"type":"text","placeholder":"Enter time to"},domProps:{"value":(_vm.detail.time_to)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.detail, "time_to", $event.target.value)}}})]),_c('div',{staticClass:"border-b-blue-dark border-r border-b relative"},[_c('date-picker',{attrs:{"format":"DD.MM.YYYY","value-type":"format","type":"date","placeholder":"Enter date to"},model:{value:(_vm.detail.date_to),callback:function ($$v) {_vm.$set(_vm.detail, "date_to", $$v)},expression:"detail.date_to"}})],1),_c('div',{staticClass:"border-b-blue-dark border-r border-b relative",class:{
      'colspan-5-7': _vm.getPermissionsByType('show_accounts_in_all_operations'),
      'colspan-4-6': !_vm.getPermissionsByType('show_accounts_in_all_operations')
    }},[_c('input-format-number',{attrs:{"inputClass":"p-l-2 p-r-12 p-y-2","placeholder":"Enter the Rate","rightPlaceholder":(_vm.activeCurrency + "/per day")},model:{value:(_vm.detail.rate),callback:function ($$v) {_vm.$set(_vm.detail, "rate", $$v)},expression:"detail.rate"}})],1),_c('div',{staticClass:"border-b-blue-dark border-b-blue-dark border-b relative"},[_c('p',{ref:"total",staticClass:"p-x-2 p-y-2 fw500 text-align-right box-border",class:{'wrap-brackets': _vm.detail.total < 0},attrs:{"contenteditable":"false"},on:{"blur":_vm.onBlur}},[_vm._v(_vm._s(Math.abs(_vm.detail.total).toFixed(2)))])]),_c('div',{staticClass:"border-r colspan-1-7 fw500 p-x-2 p-y-2 uppercase",class:{
      'colspan-1-7': _vm.getPermissionsByType('show_accounts_in_all_operations'),
      'colspan-1-6': !_vm.getPermissionsByType('show_accounts_in_all_operations')
    }},[_vm._v("Total due to owners")]),_c('div',{staticClass:"fw500 text-align-right p-x-2 p-y-2"},[_vm._v(" "+_vm._s(_vm.detail.total >= 0 ? '':'(')+" "+_vm._s(_vm._f("formatNumberTwo")(Math.abs(_vm.detail.total)))+" "+_vm._s(_vm.detail.total >= 0 ? '':')')+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }