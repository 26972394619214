<template>
  <div class="m-t-4">
    <div class="border border-dark-gray rounded-lg">
      <div class="vessel_owners__table_wide bg-blue-dark rounded-t-lg" :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }">
        <div class="border-r p-x-2 p-y-2 text-white fz13 fw500">OFF-HIRE</div>
        <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')">
          <get-trials :isWhite="true" v-if="$route.params.subId" trialKey="SUBLET_Off-Hire" />
          <get-trials :isWhite="true" v-else trialKey="Off-Hire" />
        </div>
        <div class="colspan-3-9"></div>
      </div>
      <edit-off-hire-table-row :class="{
        'border-t border-dark-gray off-hire-block': i > 0,
        'rounded-b-lg': i == hireOffData.length - 1
      }" v-for="(hire, i) in hireOffData" :key="i" :activeCurrency="activeCurrency" :hireData="hireData"
        :hireOffData="hireOffData" :hire="hire" :index="i" :lengthHire="hireData.length"
        @on-delete-block="$emit('on-delete', 'off_hire', i)" @selectIndex="changeSelectIndexHire" />
    </div>

    <!--    <div class="m-t-2" v-if="hireData.length > hireOffData.length">-->
    <!--      <button type="button" class="w-full button-blue-border fw500 fz13" @click="$emit('on-add')">-->
    <!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>-->
    <!--        Add one more Off-Hire-->
    <!--      </button>-->
    <!--    </div>-->
    <div class="m-t-2">
      <button type="button" class="w-full button-blue-border fw500 fz13" @click="$emit('on-add')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z"
            fill="#094172" />
        </svg>
        Add one more Off-Hire
      </button>
    </div>

    <button type="button" class="m-t-2 flex items-center cursor-pointer text-blue-dark fw500 fz13"
      @click="$emit('on-delete', 'off_hire')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
          fill="#094172" />
      </svg>
      Delete Off-Hire Section
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import getTrials from '@/components/vessels/owners/edit/getTrials'
import editOffHireTableRow from '@/components/vessels/owners/edit/editOffHireTableRow'

export default {
  props: {
    hireOffData: Array,
    hireData: Array,
    activeCurrency: String
  },
  components: {
    getTrials,
    editOffHireTableRow
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    changeSelectIndexHire(dataIndex) {
      // let value = this.hireOffData[dataIndex.indexThis];
      // this.hireOffData[dataIndex.indexThis] = this.hireOffData[dataIndex.indexSelect]
      // this.hireOffData[dataIndex.indexSelect] = value
      // this.hireOffData.forEach((item, index)=> {
      //     item.index_hire_selected = index;
      // })
      // this.hireOffData.sort((a,b)=> a.index_hire_selected - b.index_hire_selected)
      this.hireOffData[dataIndex.indexThis].index_hire_selected = dataIndex.indexSelect
    }
  },
  mounted() {
    // this.hireOffData.sort((a,b)=> a.index_hire_selected - b.index_hire_selected)
  }
}
</script>

<style scoped="scss">
.off-hire-block {
  border-top: 2px solid
}
</style>