<template>
  <div class="invoice_table border border-dark overflow-hidden rounded-lg" 
    :class="{'with_trials': getPermissionsByType('show_accounts_in_all_operations')}"
  >
    <div class="border-r border-b rounded-tl-lg">
      <get-trials trialKey="Detention Invoice" v-if="getPermissionsByType('show_accounts_in_all_operations')"/>
    </div>
    <div class="border-r border-b" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="border-r border-b">
      <input class="p-x-2 p-y-2" type="text" ref="timeFrom" placeholder="Enter time from" v-model="detail.time_from">
    </div>
    <div class="border-r border-b">
      <date-picker
        v-model="detail.date_from"
        format="DD.MM.YYYY"
        value-type="format"
        type="date"
        placeholder="Enter date from"
      ></date-picker>
    </div>
    <div class="border-r border-b"
      :class="{
        'colspan-5-7': getPermissionsByType('show_accounts_in_all_operations'),
        'colspan-4-6': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <counterparties-drop
        :list="timeList"
        :activeItem="activeTime"
        :removeSelectItemBeforeClick="false"
        @select="onSelectTime"
        placeholder="Select time type"
      ></counterparties-drop>
    </div>
    <div class="border-b"></div>

    <div class="border-b-blue-dark border-r border-b"></div>
    <div class="border-b-blue-dark border-r border-b" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="border-b-blue-dark border-r border-b">
      <input class="p-x-2 p-y-2" type="text" ref="timeTo" placeholder="Enter time to" v-model="detail.time_to">
    </div>
    <div class="border-b-blue-dark border-r border-b relative">
      <date-picker
        v-model="detail.date_to"
        format="DD.MM.YYYY"
        value-type="format"
        type="date"
        placeholder="Enter date to"
      ></date-picker>
    </div>
    <div class="border-b-blue-dark border-r border-b relative"
      :class="{
        'colspan-5-7': getPermissionsByType('show_accounts_in_all_operations'),
        'colspan-4-6': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <input-format-number
        v-model="detail.rate"
        inputClass="p-l-2 p-r-12 p-y-2"
        placeholder="Enter the Rate"
        :rightPlaceholder="`${activeCurrency}/per day`"
      ></input-format-number>
    </div>
    <div class="border-b-blue-dark border-b-blue-dark border-b relative">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{'wrap-brackets': detail.total < 0}"
        contenteditable="false"
        ref="total"
        @blur="onBlur"
      >{{Math.abs(detail.total).toFixed(2)}}</p>
    </div>

    <div 
      class="border-r colspan-1-7 fw500 p-x-2 p-y-2 uppercase"
      :class="{
        'colspan-1-7': getPermissionsByType('show_accounts_in_all_operations'),
        'colspan-1-6': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >Total due to owners</div>
    <div class="fw500 text-align-right p-x-2 p-y-2">
      {{detail.total >= 0 ? '':'('}}
      {{Math.abs(detail.total) | formatNumberTwo}}
      {{detail.total >= 0 ? '':')'}}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import Inputmask from 'inputmask'
import moment from 'moment'
import getTrials from '@/components/vessels/owners/edit/getTrials'
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'

export default {
  props: {
    activeCurrency: String,
    detail: Object
  },
  components: {
    DatePicker,
    getTrials,
    counterpartiesDrop,
    inputFormatNumber
  },
  data() {
    return {
      activeTime: {
        name: 'UTC',
        id: 'UTC'
      },
      timeList: [
        {
          name: 'UTC',
          id: 'UTC'
        },
        {
          name: 'LT',
          id: 'LT'
        },
      ]
    }
  },
  watch: {
    'detail.date_from': function() {this.getTotal()},
    'detail.date_to': function() {this.getTotal()},
    'detail.time_from': function() {this.getTotal()},
    'detail.time_to': function() {this.getTotal()},
    'detail.rate': function() {this.getTotal()}
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    getTotal() {
      let diff = 0

      if(this.detail.date_from && this.detail.date_to) {
        var start = moment(this.detail.date_from, "DD-MM-YYYY")
        if(this.detail.time_from)
          start.set({'hour': this.detail.time_from.split(':')[0], 'minute': this.detail.time_from.split(':')[1]})

        var end = moment(this.detail.date_to, "DD-MM-YYYY")
        if(this.detail.time_to)
          end.set({'hour': this.detail.time_to.split(':')[0], 'minute': this.detail.time_to.split(':')[1]})

        diff = end.diff(start, "minutes") / 60
      }
      let result = this.detail.rate ? this.detail.rate : 0
      result = result * diff
      
      this.setTotal(result)
    },
    onBlur(e) {
      let result = parseFloat(e.target.innerText)
      if(+this.detail.total < 0) result *= -1
      this.setTotal(result)
    },
    setTotal(result) {
      result = result ? result.toFixed(2) : 0
      this.detail.total = result
      if(this.$refs.total)
        this.$refs.total.innerText = result ? Math.abs(result).toFixed(2) : '0.00';
    },
    onSelectTime(item) {
      if(this.detail.country != item.id)
        this.detail.country = item.id
    }
  },
  mounted() {
    Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.timeFrom)
    Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.timeTo)

    
    let unwatch = this.$watch('detail.country', () => {
      if(this.detail.country)
        this.activeTime = {name: this.detail.country, id: this.detail.country}

      unwatch()
    })

    let date_from = this.detail.date_from
    let date_to = this.detail.date_to
    if(date_from){
      this.detail.date_from = moment(date_from).format('DD.MM.YYYY')
      this.detail.time_from = moment(date_from).format('HH:mm') 
    }
    if(date_to){
      this.detail.date_to = moment(date_to).format('DD.MM.YYYY')
      this.detail.time_to = moment(date_to).format('HH:mm')
    }
  }
}
</script>

<style>

</style>